<template>
  <div>
    <h1>Achievements</h1>
    <el-table
      :data="items.filter(data => !search || data.first_name.toLowerCase().includes(search.toLowerCase()))"
      v-loading="loading"
      style="width: 100%"
      @sort-change="sortTrigger"
    >
      <el-table-column label="Name" prop="name" />
      <el-table-column label="Description" prop="description" />
      <el-table-column label="Image" prop="image" />
      <el-table-column label="Image won" prop="image_won" />
      <el-table-column label="Created at" prop="created_at">
        <template #default="scope">
          {{ moment(scope.row.created_at).format("DD MMM YY [@] HH:mm") }}
        </template>
      </el-table-column>
      <el-table-column align="right">
        <template #header>
          <el-input v-model="search" size="mini" placeholder="Type to search" />
        </template>
        <template #default="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">Edit</el-button>
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >Delete</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="demo-pagination-block">
      <el-pagination
        v-model:currentPage="page"
        :page-sizes="[10, 50, 100, 200]"
        :page-size="perPage"
        layout="sizes, prev, pager, next, total"
        :total="total"
        style="margin-top: 20px"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
    <el-dialog v-model="dialogFormVisible" title="Edit Achievement">
      <el-form :model="itemBeingEdited">
        <el-form-item label="Name" :label-width="formLabelWidth">
          <el-input v-model="itemBeingEdited.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Description" :label-width="formLabelWidth">
          <el-input v-model="itemBeingEdited.description" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Image" :label-width="formLabelWidth">
          <el-input v-model="itemBeingEdited.image" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Image won" :label-width="formLabelWidth">
          <el-input v-model="itemBeingEdited.image_won" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">Cancel</el-button>
          <el-button type="primary" @click="dialogFormVisible = false">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { fetchAchievements } from "@/services"
import moment from 'moment'

export default {
  name: 'Achievements',
   data() {
    return {
      moment: moment,
      editingItem: true,
      savingInProgress: false,
      formLabelWidth: 150,
      dialogFormVisible: false,
      dialogDelete: false,
      perPage: 100,
      sortDirection: false,
      errors: null,
      page: 1,
      total: 0,
      loading: true,
      column: "created_at",
      order: "descending",
      items: [],
      search: '',
      itemBeingEdited: null,
      defaultItem: {},
    }
  },
  mounted() {
    this.getItems()
  },
  methods: {
    sortTrigger(event) {
      this.order = event.order
      this.column = event.prop
      this.getItems()
    },
    async getItems() {
      this.loading = true
      // let data = {
      //   perPage: this.perPage,
      //   page: this.page,
      //   column: this.column,
      //   order: this.order === "ascending" ? "ASC" : "DESC"
      // }
      await fetchAchievements().then(response => {
        this.items = response.data.data
        // this.total = response.total
        this.loading = false
      })
    },
    handleEdit(index, row) {
      this.itemBeingEdited = Object.assign({}, row)
      this.editingItem = true
      this.dialogFormVisible = true
    },
    handleDelete(index, row) {
      this.itemBeingEdited = Object.assign({}, row)
      this.dialogDelete = true
    },
    handleSizeChange($event) {
      this.perPage = $event
      this.getItems()
    },
    handlePageChange() {
      this.getItems()
    },
  },
}
</script>
